/*------------------------------------*\
  Full page scroll
\*------------------------------------*/

var $ = require("jquery");
var fullpage = require('fullpage.js');


var updateTitle = function (title, slug) {
  let link = '<a href="/' + slug + '" class="nav__link">' + title + '</a>';

  $('#page-title .nav').html(link);
}

global.varFullpage = null;

global.initFullpage = function () {
  varFullpage = new fullpage('#index-home', {
    verticalCentered: false,
    animateAnchor: false,
    licenseKey: '3A823061-9A394D2E-B61A75EE-FC4A2D50',
    onLeave: function (origin, destination, direction) {
      updateTitle(destination.item.dataset.title, destination.item.dataset.anchor);
      if (destination.index >= 1) {
        $(".icon__back_to_top").fadeIn();
      } else {
        $(".icon__back_to_top").fadeOut();
      };
    }
  });

  // Scroll logo
  if ($('.main').is('.main-home')) {
    $("#first-slide").click(function (e) {
      e.preventDefault();
      fullpage_api.moveSectionDown();
    });

    $("#back_to_top").click(function (e) {
      e.preventDefault();
      fullpage_api.moveTo(2);
    });
  }

}

initFullpage();
