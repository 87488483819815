/*------------------------------------*\
  pjax
\*------------------------------------*/

const $ = require("jquery");
var Pjax = require("pjax");

global.pjax = new Pjax({
  selectors: [
    "title",
    "meta[name=description]",
    "#main",
    "#secondary-nav",
    "#page-title"
  ],
  scrollRestoration: true,
  cacheBust: false,
})

// document.addEventListener('pjax:send', e => {
//   // Destroy flickity
//   if (flkty !== null) {
//     flkty.destroy();
//   }
// })

document.addEventListener('pjax:complete', e => {
  close_panels();
  // Relaunch fullpage scroll
  if (typeof (fullpage_api) !== 'undefined') {
    fullpage_api.destroy('all');
  }

  if ($('.main').is('.main-home')) {
    initFullpage();
  }

  // Launch project slideshow
  if ($('.main').is('.main-project')) {
    slideshow();
  }

})