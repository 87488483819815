/*------------------------------------*\
  lazysizes
\*------------------------------------*/

var attachlazysizes = require('lazysizes');
window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.customMedia = {};



/*! lazysizes - v4.1.5 */
/*! parent-fit plugin */
! function (a, b) {
  var c = function () {
    b(a.lazySizes), a.removeEventListener("lazyunveilread", c, !0)
  };
  b = b.bind(null, a, a.document), "object" == typeof module && module.exports ? b(require("lazysizes")) : a.lazySizes ? c() : a.addEventListener("lazyunveilread", c, !0)
}(window, function (a, b, c) {
  "use strict";
  if (a.addEventListener) {
    var d = /\s+(\d+)(w|h)\s+(\d+)(w|h)/,
      e = /parent-fit["']*\s*:\s*["']*(contain|cover|width)/,
      f = /parent-container["']*\s*:\s*["']*(.+?)(?=(\s|$|,|'|"|;))/,
      g = /^picture$/i,
      h = function (a) {
        return getComputedStyle(a, null) || {}
      },
      i = {
        getParent: function (b, c) {
          var d = b,
            e = b.parentNode;
          return c && "prev" != c || !e || !g.test(e.nodeName || "") || (e = e.parentNode), "self" != c && (d = "prev" == c ? b.previousElementSibling : c && (e.closest || a.jQuery) ? (e.closest ? e.closest(c) : jQuery(e).closest(c)[0]) || e : e), d
        },
        getFit: function (a) {
          var b, c, d = h(a),
            g = d.content || d.fontFamily,
            j = {
              fit: a._lazysizesParentFit || a.getAttribute("data-parent-fit")
            };
          return !j.fit && g && (b = g.match(e)) && (j.fit = b[1]), j.fit ? (c = a._lazysizesParentContainer || a.getAttribute("data-parent-container"), !c && g && (b = g.match(f)) && (c = b[1]), j.parent = i.getParent(a, c)) : j.fit = d.objectFit, j
        },
        getImageRatio: function (b) {
          var c, e, f, h, i, j = b.parentNode,
            k = j && g.test(j.nodeName || "") ? j.querySelectorAll("source, img") : [b];
          for (c = 0; c < k.length; c++)
            if (b = k[c], e = b.getAttribute(lazySizesConfig.srcsetAttr) || b.getAttribute("srcset") || b.getAttribute("data-pfsrcset") || b.getAttribute("data-risrcset") || "", f = b._lsMedia || b.getAttribute("media"), f = lazySizesConfig.customMedia[b.getAttribute("data-media") || f] || f, e && (!f || (a.matchMedia && matchMedia(f) || {}).matches)) {
              h = parseFloat(b.getAttribute("data-aspectratio")), !h && (i = e.match(d)) && (h = "w" == i[2] ? i[1] / i[3] : i[3] / i[1]);
              break
            } return h
        },
        calculateSize: function (a, b) {
          var c, d, e, f, g = this.getFit(a),
            h = g.fit,
            i = g.parent;
          return "width" == h || ("contain" == h || "cover" == h) && (e = this.getImageRatio(a)) ? (i ? b = i.clientWidth : i = a, f = b, "width" == h ? f = b : (d = i.clientHeight) > 40 && (c = b / d) && ("cover" == h && c < e || "contain" == h && c > e) && (f = b * (e / c)), f) : b
        }
      };
    c.parentFit = i, b.addEventListener("lazybeforesizes", function (a) {
      if (!a.defaultPrevented && a.detail.instance == c) {
        var b = a.target;
        a.detail.width = i.calculateSize(b, a.detail.width)
      }
    })
  }
});

/*! lazysizes - v4.1.5 */
/*! object-fit polyfill */
! function (a, b) {
  var c = function (d) {
    b(a.lazySizes, d), a.removeEventListener("lazyunveilread", c, !0)
  };
  b = b.bind(null, a, a.document), "object" == typeof module && module.exports ? b(require("lazysizes")) : a.lazySizes ? c() : a.addEventListener("lazyunveilread", c, !0)
}(window, function (a, b, c, d) {
  "use strict";

  function e(a) {
    var b = getComputedStyle(a, null) || {},
      c = b.fontFamily || "",
      d = c.match(j) || "",
      e = d && c.match(k) || "";
    return e && (e = e[1]), {
      fit: d && d[1] || "",
      position: n[e] || e || "center"
    }
  }

  function f(a, b) {
    var d, e, f = c.cfg,
      g = a.cloneNode(!1),
      h = g.style,
      i = function () {
        var b = a.currentSrc || a.src;
        b && e !== b && (e = b, h.backgroundImage = "url(" + (m.test(b) ? JSON.stringify(b) : b) + ")", d || (d = !0, c.rC(g, f.loadingClass), c.aC(g, f.loadedClass)))
      },
      j = function () {
        c.rAF(i)
      };
    a._lazysizesParentFit = b.fit, a.addEventListener("lazyloaded", j, !0), a.addEventListener("load", j, !0), g.addEventListener("load", function () {
      var a = g.currentSrc || g.src;
      a && a != l && (g.src = l, g.srcset = "")
    }), c.rAF(function () {
      var d = a,
        e = a.parentNode;
      "PICTURE" == e.nodeName.toUpperCase() && (d = e, e = e.parentNode), c.rC(g, f.loadedClass), c.rC(g, f.lazyClass), c.aC(g, f.loadingClass), c.aC(g, f.objectFitClass || "lazysizes-display-clone"), g.getAttribute(f.srcsetAttr) && g.setAttribute(f.srcsetAttr, ""), g.getAttribute(f.srcAttr) && g.setAttribute(f.srcAttr, ""), g.src = l, g.srcset = "", h.backgroundRepeat = "no-repeat", h.backgroundPosition = b.position, h.backgroundSize = b.fit, d.style.display = "none", a.setAttribute("data-parent-fit", b.fit), a.setAttribute("data-parent-container", "prev"), e.insertBefore(g, d), a._lazysizesParentFit && delete a._lazysizesParentFit, a.complete && i()
    })
  }
  var g = b.createElement("a").style,
    h = "objectFit" in g,
    i = h && "objectPosition" in g,
    j = /object-fit["']*\s*:\s*["']*(contain|cover)/,
    k = /object-position["']*\s*:\s*["']*(.+?)(?=($|,|'|"|;))/,
    l = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    m = /\(|\)|'/,
    n = {
      center: "center",
      "50% 50%": "center"
    };
  if (!h || !i) {
    var o = function (a) {
      if (a.detail.instance == c) {
        var b = a.target,
          d = e(b);
        !d.fit || h && "center" == d.position || f(b, d)
      }
    };
    a.addEventListener("lazyunveilread", o, !0), d && d.detail && o(d)
  }
});