/*------------------------------------*\
  menu
\*------------------------------------*/
const $ = require("jquery");


global.close_panels = function () {
  $(".panel").removeClass("active")
  menuDisplay = false;
  detailDisplay = false;
}

$("#open-primary-nav").click(function (e) {
  if (menuDisplay === false) {
    $(".panel-primary-navigation").addClass("active")
    menuDisplay = true;
  } else if (menuDisplay === true) {
    $(".panel-primary-navigation").removeClass("active")
    menuDisplay = false;
  }
  e.preventDefault();
});


$(document).on("click", '.close-panels', function (event) {
  close_panels();
});

// Escape
$(document).keyup(function (e) {
  var main_project = document.querySelector('.main-project');
  if (e.keyCode === 27) {
    if (menuDisplay | detailDisplay) {
      close_panels();
    } else if (main_project) {
      var href = $('#close-project a').attr("href");
      // console.log(href);
      pjax.loadUrl(href);
    }
  }
});



$(document).on("click", '.main .index', function (event) {
  close_panels();
});


$(document).on("click", '#open-details', function (event) {
  if (detailDisplay === false) {
    $("#panel-details").addClass("active");
    detailDisplay = true;
  } else if (detailDisplay === true) {
    $("#panel-details").removeClass("active")
    detailDisplay = false;
  }
});