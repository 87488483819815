/*------------------------------------*\
  #SCRIPTS
\*------------------------------------*/

var $ = require("jquery");
global.jQuery = require("jquery")

global.menuDisplay = false;
global.detailDisplay = false;



$(document).ready(function () {

  // modules
  require('./modules/_lazysizes.js');
  require('./modules/_is-touched.js');
  require('./modules/_fullpagescroll.js');
  require('./modules/_position.js');
  require('./modules/_panels.js');
  require('./modules/_slideshow.js');
  require('./modules/_pjax.js');
  // require('./modules/_details-project-imgs.js');
  // $('.wrapper').addClass('loaded')
});


// function unload() {
//   var isNotSafari = window.safari === undefined;
//   window.addEventListener('beforeunload', () => {
//     if (isNotSafari) $('.wrapper').addClass('unloaded')
//   });
// }

// unload();