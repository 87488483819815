/*------------------------------------*\
  #SLIDESHOW
\*------------------------------------*/
var $ = require("jquery");
var Flickity = require('flickity');
require('flickity-imagesloaded');
require('flickity-hash');

global.flkty = null;

global.slideshow = function () {
  const carousel = document.querySelector('.gallery--slideshow');

  if (carousel) {
    let carouselSelector = '.gallery--slideshow';
    let cellSelector = '.gallery__item';
    const flktySelector = carouselSelector;
    const flktyOptions = {
      hash: true,
      lazyLoad: 2,
      prevNextButtons: true,
      pageDots: false,
      percentPosition: true
    };

    flkty = new Flickity(flktySelector, flktyOptions);
    flkty.focus();

    window.addEventListener('resize', (ev) => {
      if ('destroy' in flkty) {
        flkty.destroy();
        flkty = new Flickity(flktySelector, flktyOptions);
        flkty.focus();
      }
    });

    window.addEventListener('load', (ev) => {
      flkty.resize();
      flkty = new Flickity(flktySelector, flktyOptions);
      flkty.focus();
    });

    // Counter scripts
    var carouselStatus = document.querySelector('#secondary-nav');

    function updateStatus() {
      var slideNumber = flkty.selectedIndex + 1;
      carouselStatus.textContent = slideNumber + ' / ' + flkty.slides.length;
    }
    updateStatus();
    flkty.on('select', updateStatus);
  }
}

if ($('.main').is('.main-project')) {
  slideshow();
}
